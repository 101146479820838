import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <div className="bg-warm-50 py-20">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-5xl font-bold text-warm-900 mb-6 animate-fade-in">
            Plan Your Perfect Paint Job
          </h1>
          <p className="text-xl text-warm-600 mb-8 animate-fade-in-up">
            Professional tools to help you visualize, calculate costs, and maintain your property's paint
          </p>
          <div className="flex gap-4 justify-center">
            <Link to="/get-started">
              <Button className="bg-warm-900 text-white hover:bg-warm-800">
                Get Started
              </Button>
            </Link>
            <Link to="/blog">
              <Button variant="outline" className="border-warm-900 text-warm-900">
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};