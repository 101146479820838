import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

const blogPosts = [
  {
    id: 1,
    title: "The Ultimate Guide to Exterior Paint Colors",
    excerpt: "Learn how to choose the perfect exterior paint colors for your home with our comprehensive guide.",
    date: "April 15, 2024",
    readTime: "5 min read",
    category: "Paint Selection"
  },
  {
    id: 2,
    title: "Preparing Your Home for a Paint Job",
    excerpt: "Essential steps to prepare your property before starting a painting project.",
    date: "April 12, 2024",
    readTime: "4 min read",
    category: "Preparation"
  },
  {
    id: 3,
    title: "Maintenance Tips for Painted Surfaces",
    excerpt: "Keep your painted surfaces looking fresh with these maintenance tips and tricks.",
    date: "April 10, 2024",
    readTime: "3 min read",
    category: "Maintenance"
  }
];

const Blog = () => {
  return (
    <div className="min-h-screen bg-warm-50 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-warm-900 mb-4">Paint & Property Blog</h1>
          <p className="text-warm-600 max-w-2xl mx-auto">
            Expert advice, tips, and insights about painting and property maintenance
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
          {blogPosts.map((post) => (
            <Card key={post.id} className="overflow-hidden hover-lift">
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <span className="text-sm text-warm-500">{post.date}</span>
                  <span className="bg-warm-100 text-warm-800 text-xs px-2 py-1 rounded-full">
                    {post.category}
                  </span>
                </div>
                <h2 className="text-xl font-semibold text-warm-900 mb-3">{post.title}</h2>
                <p className="text-warm-600 mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-warm-500">{post.readTime}</span>
                  <Button variant="ghost" size="sm" className="text-warm-800">
                    Read More <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;