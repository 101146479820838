import { Hero } from "@/components/Hero";
import { CostCalculator } from "@/components/CostCalculator";
import { MaintenancePlanner } from "@/components/MaintenancePlanner";
import { ColorVisualizer } from "@/components/ColorVisualizer";
import { ProfessionalHelpForm } from "@/components/ProfessionalHelpForm";

const Index = () => {
  return (
    <div className="min-h-screen bg-warm-50">
      <Hero />
      
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Our Tools</h2>
          <p className="text-warm-600 max-w-2xl mx-auto">
            Powerful tools to help you plan future painting and coating projects. The go to tool for property maintenance. 
          </p>
        </div>
        
        <div className="grid gap-8 max-w-4xl mx-auto">
          <ColorVisualizer />
          <CostCalculator />
          <MaintenancePlanner />
        </div>

        <div className="mt-20 max-w-2xl mx-auto">
          <ProfessionalHelpForm />
        </div>
      </div>
    </div>
  );
};

export default Index;