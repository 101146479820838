import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";

export const CostCalculator = () => {
  const [squareFootage, setSquareFootage] = useState("");
  const [substrate, setSubstrate] = useState("walls");
  const [condition, setCondition] = useState("good");
  const [paintType, setPaintType] = useState("standard");
  const [paintFinish, setPaintFinish] = useState("matte");
  const [address, setAddress] = useState("");

  const calculateCost = () => {
    const baseRate = 2.5; // Base rate per square foot
    const footage = parseFloat(squareFootage);
    
    if (isNaN(footage)) return { total: 0, labor: 0, materials: 0, additional: 0 };
    
    let multiplier = 1;
    if (substrate === "ceiling") multiplier *= 1.3;
    if (condition === "needs-repair") multiplier *= 1.5;
    if (paintType === "premium") multiplier *= 1.4;
    
    const materialsBase = footage * 0.8;
    const laborBase = footage * 1.5;
    const additional = condition === "needs-repair" ? footage * 0.5 : 0;
    
    const total = (footage * baseRate * multiplier);
    
    return {
      total: total.toFixed(2),
      labor: laborBase.toFixed(2),
      materials: materialsBase.toFixed(2),
      additional: additional.toFixed(2)
    };
  };

  const costs = calculateCost();

  return (
    <Card className="glass-card p-6 max-w-md mx-auto fade-in">
      <h2 className="text-2xl font-semibold mb-6">Cost Calculator</h2>
      
      <div className="space-y-4">
        <div>
          <Label htmlFor="address">Property Address</Label>
          <Input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="mt-1"
            placeholder="Enter property address"
          />
        </div>

        <div>
          <Label htmlFor="square-footage">Square Footage</Label>
          <Input
            id="square-footage"
            type="number"
            value={squareFootage}
            onChange={(e) => setSquareFootage(e.target.value)}
            className="mt-1"
            placeholder="Enter square footage"
          />
        </div>

        <div>
          <Label htmlFor="substrate">Surface Type</Label>
          <Select value={substrate} onValueChange={setSubstrate}>
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select surface type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="walls">Walls</SelectItem>
              <SelectItem value="ceiling">Ceiling</SelectItem>
              <SelectItem value="trim">Trim</SelectItem>
              <SelectItem value="floors">Floors</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="condition">Current Condition</Label>
          <Select value={condition} onValueChange={setCondition}>
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select condition" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="good">Good</SelectItem>
              <SelectItem value="needs-repair">Needs Repair</SelectItem>
              <SelectItem value="minor-touchup">Minor Touch-ups</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="paint-type">Paint Type</Label>
          <Select value={paintType} onValueChange={setPaintType}>
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select paint type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="standard">Standard</SelectItem>
              <SelectItem value="premium">Premium</SelectItem>
              <SelectItem value="eco-friendly">Eco-Friendly</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="paint-finish">Paint Finish</Label>
          <Select value={paintFinish} onValueChange={setPaintFinish}>
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select paint finish" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="matte">Matte</SelectItem>
              <SelectItem value="eggshell">Eggshell</SelectItem>
              <SelectItem value="satin">Satin</SelectItem>
              <SelectItem value="semi-gloss">Semi-Gloss</SelectItem>
              <SelectItem value="gloss">Gloss</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="pt-4 space-y-2">
          <div className="text-lg font-medium">
            Total Estimated Cost: ${costs.total}
          </div>
          <div className="space-y-1 text-sm text-muted-foreground">
            <div>Labor: ${costs.labor}</div>
            <div>Materials: ${costs.materials}</div>
            <div>Additional Services: ${costs.additional}</div>
          </div>
          <p className="text-sm text-muted-foreground mt-2">
            This is a base estimate. Contact us for a detailed quote.
          </p>
        </div>
      </div>
    </Card>
  );
};