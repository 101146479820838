import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface Task {
  id: number;
  name: string;
  date: string;
}

export const MaintenancePlanner = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState("");
  const [taskDate, setTaskDate] = useState("");

  const addTask = () => {
    if (newTask && taskDate) {
      setTasks([...tasks, { id: Date.now(), name: newTask, date: taskDate }]);
      setNewTask("");
      setTaskDate("");
    }
  };

  return (
    <Card className="glass-card p-6 max-w-md mx-auto fade-in">
      <h2 className="text-2xl font-semibold mb-6">Maintenance Planner</h2>
      
      <div className="space-y-4">
        <div>
          <Label htmlFor="task">Task Name</Label>
          <Input
            id="task"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            placeholder="Enter task name"
            className="mt-1"
          />
        </div>

        <div>
          <Label htmlFor="date">Due Date</Label>
          <Input
            id="date"
            type="date"
            value={taskDate}
            onChange={(e) => setTaskDate(e.target.value)}
            className="mt-1"
          />
        </div>

        <Button onClick={addTask} className="w-full">
          Add Task
        </Button>

        <div className="mt-6">
          {tasks.map((task) => (
            <div
              key={task.id}
              className="p-3 mb-2 bg-warm-50 rounded-lg flex justify-between items-center hover-lift"
            >
              <span>{task.name}</span>
              <span className="text-sm text-warm-600">{task.date}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};