import { CostCalculator } from "@/components/CostCalculator";
import { ProfessionalHelpForm } from "@/components/ProfessionalHelpForm";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useState } from "react";

const GetStarted = () => {
  const [paintLocation, setPaintLocation] = useState("");
  const [hasSelectedColor, setHasSelectedColor] = useState("");

  return (
    <div className="min-h-screen bg-warm-50 py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-warm-900 mb-8 text-center">
          Let's Get Started
        </h1>
        <div className="max-w-4xl mx-auto space-y-16">
          <div className="space-y-8 glass-card p-6 fade-in">
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-warm-900">Quick Questions</h2>
              
              <div className="space-y-3">
                <Label className="text-base">Where would you like to paint?</Label>
                <RadioGroup value={paintLocation} onValueChange={setPaintLocation}>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="interior" id="interior" />
                    <Label htmlFor="interior">Interior</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="exterior" id="exterior" />
                    <Label htmlFor="exterior">Exterior</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="both" id="both" />
                    <Label htmlFor="both">Both</Label>
                  </div>
                </RadioGroup>
              </div>

              <div className="space-y-3">
                <Label className="text-base">Have you selected a paint color?</Label>
                <RadioGroup value={hasSelectedColor} onValueChange={setHasSelectedColor}>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="yes" id="color-yes" />
                    <Label htmlFor="color-yes">Yes</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="no" id="color-no" />
                    <Label htmlFor="color-no">No</Label>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          <CostCalculator />
          <ProfessionalHelpForm />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;