import { useState, useRef, useEffect } from "react";
import { Canvas, Image } from "fabric";
import { Palette } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { toast } from "sonner";

const popularColors = [
  { name: "Classic White", hex: "#F5F5F5" },
  { name: "Warm Gray", hex: "#B8B2A7" },
  { name: "Modern Beige", hex: "#E3D5C7" },
  { name: "Soft Blue", hex: "#D3E4FD" },
  { name: "Sage Green", hex: "#B2BDA0" },
];

export const ColorVisualizer = () => {
  const [canvas, setCanvas] = useState<Canvas | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedColor, setSelectedColor] = useState(popularColors[0].hex);

  useEffect(() => {
    if (!canvasRef.current) return;

    const fabricCanvas = new Canvas(canvasRef.current, {
      width: 800,
      height: 600,
      backgroundColor: "#ffffff",
    });

    setCanvas(fabricCanvas);

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!canvas || !e.target.files?.[0]) return;

    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        if (!event.target?.result) return;

        const imageUrl = event.target.result as string;
        Image.fromURL(imageUrl, {
          crossOrigin: 'anonymous',
        }).then((img) => {
          canvas.clear();
          
          // Fit image to canvas while maintaining aspect ratio
          const scale = Math.min(
            canvas.getWidth() / img.width!,
            canvas.getHeight() / img.height!
          );
          
          img.scale(scale);
          img.set({
            left: canvas.getWidth() / 2,
            top: canvas.getHeight() / 2,
            originX: 'center',
            originY: 'center'
          });
          canvas.add(img);
          canvas.renderAll();
          
          toast.success("Image uploaded successfully!");
        });
      };

      reader.readAsDataURL(file);
    } catch (error) {
      toast.error("Error uploading image");
    }
  };

  const applyColor = () => {
    if (!canvas) return;

    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      toast.error("Please select an area to color");
      return;
    }

    activeObject.set('fill', selectedColor);
    canvas.renderAll();
    toast.success("Color applied!");
  };

  return (
    <Card className="p-6 space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-2">Color Visualizer</h2>
        <p className="text-muted-foreground">
          Upload a photo and preview different paint colors
        </p>
      </div>

      <div className="flex gap-4 items-center justify-center">
        <Input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="max-w-[300px]"
          id="image-upload"
        />
        <Button onClick={applyColor} className="flex gap-2">
          <Palette className="w-4 h-4" />
          Apply Color
        </Button>
      </div>

      <div className="flex gap-4 flex-wrap justify-center">
        {popularColors.map((color) => (
          <button
            key={color.hex}
            onClick={() => setSelectedColor(color.hex)}
            className={`w-12 h-12 rounded-full border-2 transition-all ${
              selectedColor === color.hex ? 'border-black scale-110' : 'border-gray-200'
            }`}
            style={{ backgroundColor: color.hex }}
            title={color.name}
          />
        ))}
      </div>

      <div className="border rounded-lg overflow-hidden">
        <canvas ref={canvasRef} className="max-w-full" />
      </div>

      <div className="text-sm text-muted-foreground text-center">
        <p>Click and drag on the image to select areas for coloring</p>
        <p>Choose from our recommended colors or input your own</p>
      </div>
    </Card>
  );
};